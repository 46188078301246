import * as React from "react"
import HeardTopWrapper from "../../components/header-wrapper"
import Layout from "../../components/layout/index"
import PageContainer from "../../components/page-container"
import { Link,graphql } from "gatsby"
import { Helmet } from 'react-helmet';
import {useTranslation} from 'gatsby-plugin-react-i18next';
const Blog = ({ data }) => {
  const {t} = useTranslation();
  const {metaDesc,metaKeywords,title} = data.wpPost.seo
  return(
    <>
      <HeardTopWrapper title={data.wpPost.title}/>
      <Layout pageTitle="Blog">
        <Helmet>
          <title>{t('myKitchenInspector')}｜{data.wpPost.title}</title>
          <meta
            name='description'
            content={metaDesc}
          />
          <meta 
            name="keywords" 
            content={metaKeywords}/>
        </Helmet>
        <PageContainer>
          <>
          <div className="topBreadscrum">
            <div className="topBreadscrumLeft"><Link to="/blog" className="top-nav">{t('blogList')}</Link> &gt; {data.wpPost.title}</div>
          </div>
            <h4>{data.wpPost.title}</h4>
            
            <div dangerouslySetInnerHTML={{ __html: data.wpPost.content }}></div>
          </>
        </PageContainer>
      </Layout>
    </>
    
  )
}
export const query = graphql`
    query($slug: String!,$language: String!) {
      locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
      wpPost(slug: {eq: $slug}) {
        title
        id
        content
        date
        seo {
          canonical
          focuskw
          metaDesc
          metaKeywords
          metaRobotsNofollow
          metaRobotsNoindex
          opengraphAuthor
          opengraphDescription
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          title
          twitterDescription
          twitterTitle
        }
      }
    }
`

export default Blog
